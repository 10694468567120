import "react-chat-elements/dist/main.css"
import { MessageBox } from "react-chat-elements";
import { Modal } from "antd";
import { useState, useEffect,useRef } from "react";
import { MessageList } from "react-chat-elements"
import KvozaHeader from "./KvozaHeader";
import { ArrowDownOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import back from "./back";
function App() {
  const [users, setUsers] = useState(undefined)
  const [messages, setMessages] = useState(undefined)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const downButtonRef = useRef(null)
  const divRef = useRef(null)
  const imageLink = useRef(null)
  const old = useRef(0)

  const getMessages = () => {
    if(messages !== undefined){
      fetch(back + "/messages/"+messages[messages.length-1].id)
      .then(response => response.json())
      .then(data => {
        if(data.length > 0){
          setMessages(msgs => [...msgs, ...data])
        }
      })
    }else{
      fetch(back + "/messages")
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        setMessages(data)
      })
    }
  }
  
  const handleOpenPhoto = (message) => {
    if(message.type === "photo"){
      imageLink.current = message.data.uri
      setIsModalOpen(true)
    }
  }
  const getUsers = () => {
    fetch(back + "/users")
      .then(response => response.json())
      .then(data => setUsers(data))
  }

  const getReply = (message) => {
    const reply = messages.filter(msg => msg.id === message.reply_to)

    if(reply.length === 0){
      return null
    }
    return {
      message: reply[0].text,
      titleColor: "#8717ae",
      type: reply[0].media_id === null ? "text" : reply[0].type === "photo" ? "photo" : "video",
      data: reply[0].media_id === null ? null : reply[0].type === "photo" || reply[0].type === "sticker" ? {
        uri: back+"/media/"+reply[0].media_id,
        width: 100
      } : {
        videoURL: back+"/media/"+reply[0].media_id,
        status: {
          click: true,
          loading: 0.5,
          download: true,
          width: 200,
          height: 200
        },
      },
      title: users[reply[0].user_id].username,
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getMessages();
      getUsers()
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [messages]);

  useEffect(() => {
    if(divRef.current && old.current > 100){
      divRef.current.scrollTop = divRef.current.scrollHeight - old.current
    }
  }, [messages]); 

  return (
    <div className="App"
    >
      {
        messages && users &&
        <>
          <KvozaHeader users={users} messages={messages}/> 
          <MessageList
            className='message-list'
            lockable={true}
            toBottomHeight={50}
            onTitleClick={(e) => {
              window.open("https://t.me/"+e.title, "_blank")
            }}
            onScroll={(e) => {
              if(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 100){
                downButtonRef.current.style.display = "none"
              }else{
                downButtonRef.current.style.display = "block"
              }
              if(e.target.scrollTop === 0){
                fetch(back + "/messagestop/"+messages[0].id)
                .then(response => response.json())
                .then(data => {
                  if(data.length > 0){
                    old.current = e.target.scrollHeight
                    setMessages(msgs => [...data, ...msgs])
                  }
                })
              }
            }}
            onOpen={handleOpenPhoto}
            referance={divRef}
            dataSource={
              messages.map((message, index) => (
                {
                  position: (message.user_id === 1922247324 ? "right" : "left"),
                  type: message.media_id === null ? "text" : message.type === "photo" ? "photo" : "video",
                  titleColor: (message.user_id === 1922247324) ? "#ff4a4a" : "#8717ae",
                  status: "read",
                  text: message.text,
                  data: message.media_id === null ? null : message.type === "photo" || message.type === "sticker" ? {
                    uri: back+"/media/"+message.media_id,
                    width: "100%",
                    height: "auto"
                  } : {
                    videoURL: back+"/media/"+message.media_id,
                    status: {
                      click: true,
                      loading: 0.5,
                      download: true,
                      width: 200,
                      height: 200
                    },
                  },
                  reply: getReply(message),
                  avatar: back+"/media/"+users[message.user_id].picture,
                  title: users[message.user_id].username,
                  date: new Date(message.date),
                })
              )
            }
            />
        </>
      }
      <Modal open={isModalOpen} footer={null}
        onCancel = {
          () => {
            setIsModalOpen(false)
          }
        }
      >
        <img src={imageLink.current} style={{
          width: "100%",
          height: "auto"
        }}/>
      </Modal>
      <FloatButton
        ref={downButtonRef}
        onClick={() => {
          divRef.current.scrollTop = divRef.current.scrollHeight
        }}
        shape="circle"
        type="primary"
        icon={<ArrowDownOutlined />}
      />
      {/* <button className="down" onClick={() => {
        divRef.current.scrollTop = divRef.current.scrollHeight
      }} ref={downButtonRef}>down</button> */}
    </div>
  );
}

export default App;
